import React, { useState } from "react";
import { toKwacha } from "./../../utils/toKwacha";
import { BiPrinter } from "react-icons/bi";
import { FinReportDoc, Modal } from "..";
import { formattedAmount } from "../../utils/formattedAmount";

const FinReport = ({ receipts, income, payments, gvtFees, dates }) => {
  const [modalContent, setModalContent] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [bankedCash, setBankedCash] = useState("");
  const [error, setError] = useState("");

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const calculateTotal = (data) =>
    data.reduce((sum, item) => sum + item.amount, 0);

  const sumReceipts = calculateTotal(receipts);
  const sumGvt = calculateTotal(gvtFees);
  const totalIncome = calculateTotal(income);
  const totalPayments = calculateTotal(payments);

  const receiptsBanked = calculateTotal(
    receipts.filter((item) => item.modeOfPayment !== "Cash")
  );
  const incomeBanked = calculateTotal(
    income.filter((item) => item.mode !== "Cash")
  );
  const cashReceipts = calculateTotal(
    receipts.filter((item) => item.modeOfPayment === "Cash")
  );
  const cashIncome = calculateTotal(
    income.filter((item) => item.mode === "Cash")
  );
  const cashGvt = calculateTotal(
    gvtFees.filter((item) => item.modeOfPayment === "Cash")
  );

  const totalCash = cashReceipts + cashIncome + cashGvt;

  let cashInHand = totalCash - totalPayments;

  const totalBanked = receiptsBanked + incomeBanked;
  const totalReceipts = sumReceipts + totalIncome + sumGvt;

  const handleChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = value;

    if (name === "amount") {
      // Remove any commas from the value
      updatedValue = value.replace(/,/g, "");
    }

    setBankedCash(updatedValue);
  };

  function validate() {
    let formErrors = {};
    if (bankedCash.length > 0) {
      if (isNaN(bankedCash) || bankedCash <= 0) {
        formErrors.amount = "Amount must be a valid number and greater than 0";
      }
    }
    return formErrors;
  }

  const handleReport = () => {
    const validationErrors = validate();

    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
    } else {
      setError({});

      if (bankedCash.length > 0) {
        cashInHand = cashInHand - bankedCash;
      }

      setModalContent(
        <FinReportDoc
          receipts={receipts}
          income={income}
          payments={payments}
          gvtFees={gvtFees}
          closeModal={closeModal}
          totalReceipts={totalReceipts}
          totalPayments={totalPayments}
          totalBanked={totalBanked}
          cashInHand={cashInHand}
          bankedCash={bankedCash}
          dates={dates}
        />
      );
      openModal();
    }
  };

  return (
    <div className="container mx-auto p-2 flex flex-col">
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        modalContent={modalContent}
      />
      <div className="flex self-end  justify-center items-center gap-2 my-2">
        <div>
          <input
            id="receiptNo"
            name="amount"
            type="text"
            placeholder="Banked cash"
            value={formattedAmount(bankedCash.toString())}
            onChange={handleChange}
            className={`w-full p-2 border rounded ${
              error.amount ? "border-red-500" : "border-black"
            }`}
          />
          {error.amount && (
            <p className="text-red-500 text-xs mt-1">{error.amount}</p>
          )}
        </div>

        <button
          onClick={handleReport}
          className="flex text-lg font-bold gap-2 animate-pulse text-black mr-[35px]"
        >
          <BiPrinter size={28} className="text-white" title="Print Report" />
          Print
        </button>
      </div>

      {/* Tables Container */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:h-[60vh]">
        {/* Receipts Table */}
        <div className="bg-white shadow-md rounded-lg">
          <h2 className="text-md font-semibold text-center p-2 text-blue-500">
            All Receipts
          </h2>
          <p className="text-gray-400 text-center">
            including fees, government fees and other incomes
          </p>

          <div className="overflow-auto md:h-[50vh]">
            <table className="w-full table-auto">
              <thead className="bg-gray-100 text-sm">
                <tr>
                  <th className="px-4 py-2">R. No</th>
                  <th className="px-4 py-2">Source</th>
                  <th className="px-4 py-2">Amount</th>
                  <th className="px-4 py-2">Mode</th>
                  <th className="px-4 py-2">Date</th>
                </tr>
              </thead>
              <tbody className="text-sm">
                {receipts.map((receipt) => (
                  <tr key={receipt.id} className="border-t">
                    <td className="px-4 py-2">{receipt?.receiptNo}</td>
                    <td className="px-4 py-2">{receipt?.Student?.fullname}</td>
                    <td className="px-4 py-2">{toKwacha(receipt?.amount)}</td>
                    <td className="px-4 py-2">{receipt?.modeOfPayment}</td>
                    <td className="px-4 py-2">{receipt?.dateOfPayment}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={5} className="bg-gray-200 font-bold p-2">
                    Government fees
                  </td>
                </tr>
                {gvtFees.map((receipt) => (
                  <tr key={receipt.id} className="border-t">
                    <td className="px-4 py-2">{receipt?.receiptNo}</td>
                    <td className="px-4 py-2">
                      {receipt?.Student?.fullname} ({receipt?.paymentOf})
                    </td>
                    <td className="px-4 py-2">{toKwacha(receipt?.amount)}</td>
                    <td className="px-4 py-2">{receipt?.modeOfPayment}</td>
                    <td className="px-4 py-2">{receipt?.dateOfPayment}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={5} className="bg-gray-200 font-bold p-2">
                    Other income
                  </td>
                </tr>
                {income.map((receipt) => (
                  <tr key={receipt.id} className="border-t">
                    <td className="px-4 py-2">{receipt?.receiptNo}</td>
                    <td className="px-4 py-2">{receipt?.mirage}</td>
                    <td className="px-4 py-2">{toKwacha(receipt?.amount)}</td>
                    <td className="px-4 py-2">{receipt?.mode}</td>
                    <td className="px-4 py-2">{receipt?.dateOfPayment}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Payments Table */}
        <div className="bg-white shadow-md rounded-lg">
          <h2 className="text-md font-semibold text-center p-2 text-red-500">
            Payments
          </h2>
          <br />
          <div className="overflow-auto md:h-[50vh]">
            <table className="w-full table-auto text-sm">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-4 py-2">Description</th>
                  <th className="px-4 py-2">Amount</th>
                  <th className="px-4 py-2">Mode</th>
                  <th className="px-4 py-2">Date</th>
                </tr>
              </thead>
              <tbody>
                {payments.map((payment) => (
                  <tr key={payment.id} className="border-t">
                    <td className="px-4 py-2">{payment?.mirage}</td>
                    <td className="px-4 py-2">{toKwacha(payment?.amount)}</td>
                    <td className="px-4 py-2">{payment?.mode}</td>
                    <td className="px-4 py-2">{payment?.dateOfPayment}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Summary Section */}
      <div className="bg-gray-200 p-6 mt-8 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-4 text-center">Summary</h2>
        <div className="flex flex-col md:flex-row justify-around text-center md:gap-1 gap-[30px] ">
          <div className="mb-2">
            <p className="text-lg font-medium">Total Sales</p>
            <p className="text-xl font-semibold text-blue-600">
              {toKwacha(totalReceipts)}
            </p>
          </div>
          <div className="mb-2">
            <p className="text-lg font-medium">Total Payments</p>
            <p className="text-xl font-semibold text-red-600">
              {toKwacha(totalPayments)}
            </p>
          </div>
          <div className="mb-2">
            <p className="text-lg font-medium">Banked</p>
            <p className={`text-xl font-semibold text-cyan-600`}>
              {toKwacha(totalBanked)}
            </p>
          </div>
          <div className="mb-2">
            <p className="text-lg font-medium">Cash in Hand</p>
            <p
              className={`text-xl font-semibold ${
                cashInHand >= 0 ? "text-green-600" : "text-red-600"
              }`}
            >
              {toKwacha(cashInHand)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinReport;
