import React, { useEffect, useState } from "react";
import { Loading, QuickPassLogo } from "..";
import html2pdf from "html2pdf.js";
import { readableTimestamp } from "../../utils/readableTimeStamps";

const Report = ({ closeModal, data }) => {
  const [loading, setLoading] = useState(false);

  const generatePdf = async () => {
    setLoading(true);
    try {
      // Capture the HTML content
      const reportHtml = document.getElementById("report-content");

      // Configure html2pdf.js options
      const options = {
        margin: 1,
        filename: `${data.Student.fullname} receipt ${
          data.receiptNo
        }-${readableTimestamp()}.pdf`,
        html2canvas: { scale: 2 }, // Increase scale for better quality
        jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
      };

      // Generate and download the PDF
      await html2pdf().set(options).from(reportHtml).save();
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoading(false);
      closeModal(); // Close the modal after generating the PDF
    }
  };

  useEffect(() => {
    generatePdf(); // Automatically generate the PDF when the component is mounted
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      className="p-8 bg-white rounded-md shadow-md w-full max-w-md mx-auto"
      id="report-content"
    >
      {/* Header with Logo and Title */}
      <div className="flex items-center justify-between border-b pb-4 mb-4">
        <QuickPassLogo />
        <h1 className="text-lg font-semibold text-gray-800 text-right">
          Transaction Receipt
        </h1>
      </div>

      {/* Receipt Info */}
      <div className="text-gray-700">
        {/* Receipt Details */}
        <div className="mb-2">
          <p className="text-right">
            <strong>Receipt No:</strong> {data.receiptNo}
          </p>
          <p className="text-right">
            <strong>Date paid:</strong> {data.dateOfPayment}
          </p>
        </div>

        {/* Payment Details */}
        <div className="mb-4">
          <p>
            <strong>Payment Of:</strong> {data.paymentOf}
          </p>
          <p>
            <strong>Mode of Payment:</strong> {data.modeOfPayment}
          </p>
          <p className="text-lg my-2">
            <strong>Sum of:</strong> MK {data.amount.toLocaleString()}
          </p>
          {data.modeOfPayment === "Cheque" && (
            <p>
              <strong>Cheque No:</strong> {data.chequeNo}
            </p>
          )}
          <p>
            <strong>Reference:</strong> {data.reference}
          </p>
        </div>

        {/* Student Details */}
        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-2 text-center">
            Student Information
          </h2>
          <p className="text-right">
            <strong>Student ID:</strong> {data.Student.studentID}
          </p>
          <p className="text-right">
            <strong>Full Name:</strong> {data.Student.fullname}
          </p>
          <p className="text-right">
            <strong>Phone:</strong> {data.Student.phone}
          </p>
          <p className="text-right">
            <strong>Course:</strong> {data.Student.course}
          </p>
          <p className="text-right">
            <strong>Duration:</strong> {data.Student.duration}
          </p>
          <p className="text-right">
            <strong>Registered On:</strong> {data.Student.dateRegistered}
          </p>
        </div>

        {/* User Info */}
        <div className="text-left">
          <p>
            <strong>Processed by:</strong> {data.user}
          </p>
          <p className="my-2">
            <strong>Signature:</strong>__________________
          </p>
        </div>
      </div>
    </div>
  );
};

export default Report;
