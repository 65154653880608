import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { toKwacha } from "../../utils/toKwacha";
import { readableTimestamp } from "../../utils/readableTimeStamps";
import Loading from "../Loading";
import logo from "./../../assets/img/qpds logo.jpg"; // Ensure the path is correct

const Report = ({
  receipts,
  income,
  payments,
  totalReceipts,
  totalPayments,
  totalBanked,
  cashInHand,
  gvtFees,
  dates,
  bankedCash,
  closeModal,
}) => {
  const [loading, setLoading] = useState(false);

  const generatePdf = () => {
    const doc = new jsPDF();

    // Add logo
    const imgWidth = 20;
    const imgHeight = 20;
    doc.addImage(logo, "JPEG", 10, 10, imgWidth, imgHeight);

    // Add title and metadata
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    doc.text("QuickPass Driving School", imgWidth + 20, 20);

    doc.setFontSize(14);
    doc.setFont("helvetica", "normal");
    doc.text("Cash Flow Report", imgWidth + 20, 30);

    // Add metadata in a single row
    doc.setFontSize(12);
    const metadata = `Date range: From ${dates?.startDate} to ${
      dates?.endDate || "N/A"
    } | Date: ${new Intl.DateTimeFormat("en-GB").format(new Date())}
    `;
    doc.text(metadata, 10, 40);

    // Combine all records into a single table
    doc.setFontSize(13);
    doc.text("Sales, Government Fees & Other Income", 10, 55);
    doc.autoTable({
      startY: 60,
      head: [["R. No", "Source", "Amount", "Mode", "Date", "Category"]],
      body: [
        // Map Sales records
        ...receipts.map((receipt) => [
          receipt?.receiptNo,
          receipt?.Student?.fullname,
          toKwacha(receipt?.amount),
          receipt?.modeOfPayment,
          receipt?.dateOfPayment,
          "Sales", // Add category
        ]),
        // Map Government Fees records
        ...gvtFees.map((receipt) => [
          receipt?.receiptNo,
          `${receipt?.Student?.fullname} (${receipt?.paymentOf})`,
          toKwacha(receipt?.amount),
          receipt?.modeOfPayment,
          receipt?.dateOfPayment,
          "Government Fees", // Add category
        ]),
        // Map Other Income records
        ...income.map((receipt) => [
          receipt?.receiptNo,
          receipt?.mirage,
          toKwacha(receipt?.amount),
          receipt?.mode,
          receipt?.dateOfPayment,
          "Other Income", // Add category
        ]),
      ],
    });

    // Calculate the Y position for the next table
    let nextY = doc.lastAutoTable.finalY + 10;

    // Add Payments Table
    doc.text("Payments", 10, nextY);
    doc.autoTable({
      startY: nextY + 5,
      head: [["Description", "Amount", "Mode", "Date"]],
      body: payments.map((payment) => [
        payment?.mirage,
        toKwacha(payment?.amount),
        payment?.mode,
        payment?.dateOfPayment,
      ]),
    });

    // Calculate the Y position for the Summary section
    nextY = doc.lastAutoTable.finalY + 10;

    // Add Summary
    doc.setFontSize(16);
    doc.text("Report Summary", 10, nextY);
    doc.autoTable({
      startY: nextY + 5,
      body: [
        ["Total Sales", toKwacha(totalReceipts)],
        ["Total Payments", toKwacha(totalPayments)],
        ["Banked", toKwacha(totalBanked)],
        ["Banked Cash", toKwacha(bankedCash.length > 0 ? bankedCash : 0)],
        [
          "Cash in Hand",
          `${cashInHand >= 0 ? "+" : ""}${toKwacha(cashInHand)}`,
        ],
      ],
      theme: "striped",
      didParseCell: (data) => {
        if (data.row.index === 3 && cashInHand < 0) {
          data.cell.styles.textColor = [255, 0, 0];
        }
      },
    });

    // Save PDF
    doc.save(`cash-flow-${readableTimestamp()}.pdf`);
  };

  useEffect(() => {
    setLoading(true);
    const print = async () => {
      try {
        await generatePdf();
      } catch (error) {
        console.error("Error generating PDF:", error);
      } finally {
        setLoading(false);
        closeModal();
      }
    };

    print();
  }, []); // Trigger on mount

  return (
    <div className="container mx-auto p-6 gap-6 flex flex-col">
      {loading && <Loading />}
    </div>
  );
};

export default Report;
