import React, { useEffect, useState } from "react";
import { Loading, QuickPassLogo } from "..";
import { readableTimestamp } from "../../utils/readableTimeStamps";
import html2pdf from "html2pdf.js";

const Report = ({ closeModal, data, student }) => {
  const [loading, setLoading] = useState(false);

  const generatePdf = () => {
    const reportContent = document.getElementById("report-content");
    const options = {
      margin: 0.5,
      filename: `${student.fullname} receipt ${
        data.receiptNo
      }-${readableTimestamp()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // Generate PDF
    html2pdf().set(options).from(reportContent).save();
  };

  useEffect(() => {
    setLoading(true);
    const print = async () => {
      generatePdf(); // Generate the PDF
      setLoading(false);
      closeModal(); // Close the modal after the PDF is generated
    };

    print();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      className="p-8 bg-white rounded-md shadow-md w-full max-w-md mx-auto"
      id="report-content"
    >
      {/* Header with Logo and Title */}
      <div className="flex items-center justify-between border-b pb-4 mb-4">
        <QuickPassLogo />
        <h1 className="text-lg font-semibold text-gray-800 text-right">
          Transaction Receipt
        </h1>
      </div>

      {/* Receipt Info */}
      <div className="text-gray-700">
        <div className="mb-2">
          <p className="text-right">
            <strong>Receipt No:</strong> {data.receiptNo}
          </p>
          <p className="text-right">
            <strong>Date paid:</strong> {data.dateOfPayment}
          </p>
        </div>

        {/* Payment Details */}
        <div className="my-4">
          <p>
            <strong>Payment Of:</strong> {data.paymentOf}
          </p>
          <p>
            <strong>Mode of Payment:</strong> {data.modeOfPayment}
          </p>
          <p className="text-lg my-2">
            <strong>Sum of:</strong> MK {data.amount.toLocaleString()}
          </p>
          {data.modeOfPayment === "Cheque" && (
            <p>
              <strong>Cheque No:</strong> {data.chequeNo}
            </p>
          )}
          <p>
            <strong>Reference:</strong> {data.reference}
          </p>
        </div>

        {/* Student Details */}
        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-2 text-center">
            Student Information
          </h2>
          <p className="text-right">
            <strong>Student ID:</strong> {student.studentID}
          </p>
          <p className="text-right">
            <strong>Full Name:</strong> {student.fullname}
          </p>
          <p className="text-right">
            <strong>Phone:</strong> {student.phone}
          </p>
          <p className="text-right">
            <strong>Course:</strong> {student.course}
          </p>
          <p className="text-right">
            <strong>Duration:</strong> {student.duration}
          </p>
          <p className="text-right">
            <strong>Registered On:</strong> {student.dateRegistered}
          </p>
        </div>

        {/* User Info */}
        <div className="text-left">
          <p>
            <strong>Processed by:</strong> {data.user}
          </p>
          <p className="my-2">
            <strong>Signature:</strong>__________________
          </p>
        </div>
      </div>
    </div>
  );
};

export default Report;
