import React, { useEffect, useState } from "react";
import { BiData } from "react-icons/bi";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import useApi from "../hooks/useApi";
import { apiUrl } from "../utils/links";
import { BalancesPaginated, Loading } from "../components";

const FeesBalance = () => {
  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
    course: "",
  });
  const [errors, setErrors] = useState({});
  const { data, error, setData, loading, refetch } = useApi();

  const headers = {
    loginToken: localStorage.getItem("loginToken"),
  };

  const {
    data: courses,
    loading: isCourseLoading,
    error: courseError,
    refetch: getCourses,
  } = useApi();

  useEffect(() => {
    const loadCourses = async () => {
      await getCourses(`${apiUrl}/courses`, "GET", null, { headers });
    };

    loadCourses();
  }, []);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDates({
      ...dates,
      [name]: value,
    });
  };

  const validate = () => {
    let formErrors = {};

    if (!dates.startDate) {
      formErrors.startDate = "Start date is required";
    }

    if (!dates.endDate) {
      formErrors.endDate = "End date is required";
    }

    if (
      dates.startDate &&
      dates.endDate &&
      new Date(dates.startDate) > new Date(dates.endDate)
    ) {
      formErrors.endDate =
        "End date should be greater than or equal to start date";
    }

    if (!dates.course) {
      formErrors.course = "Course is required";
    }

    return formErrors;
  };

  const handleLoadData = async () => {
    const validationErrors = validate();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      const errorVals = Object.values(validationErrors);
      const stringVals = errorVals.join(", ");
      Swal.fire({
        title: "Validation Error!",
        text: JSON.stringify(stringVals),
        icon: "error",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        width: "400px", // Set custom width
        customClass: {
          popup: "small-swal",
        },
      });
    } else {
      setErrors({});
      await refetch(
        `${apiUrl}/students/${dates.startDate}/${dates.endDate}/${dates.course}`,
        "GET",
        null,
        { headers }
      );
    }
  };

  return (
    <div className="w-full flex h-full flex-col items-center relative gap-3">
      <div className="self-start">
        <Link to={"/dashboard"} className="text-yellow-700 py-1">
          Dashboard
        </Link>
        {" > "}
        Fees Balances
      </div>
      <div>{isCourseLoading && <Loading />}</div>
      <div className="text-lg text-red-600 w-full text-center p-3">
        {courseError && courseError}
      </div>
      <div className="text-lg font-bold">Monitor Fees Balances</div>

      <div className="w-full flex lg:flex-row  items-center flex-col gap-4 justify-start md:gap-[15px] mt-3 border-b-2 border-b-gray-300 rounded-lg bg-gray-100 p-2">
        <div className="relative flex flex-col items-center">
          <label htmlFor="startDate">Start date</label>
          <input
            type="date"
            name="startDate"
            value={dates.startDate}
            onChange={handleDateChange}
            className={`ml-2 border border-black rounded pr-[15px] text-sm p-[7px] w-[200px] ${
              errors.startDate ? "border-red-500" : "border-black"
            }`}
            placeholder="Select start date"
          />
          {errors.startDate && (
            <p className="text-red-500 text-xs mt-1">{errors.startDate}</p>
          )}
        </div>
        <div className="relative flex flex-col items-center">
          <label htmlFor="endDate">End date</label>
          <input
            type="date"
            name="endDate"
            value={dates.endDate}
            onChange={handleDateChange}
            className={`ml-2 border border-black rounded pr-[15px] text-sm p-[7px] w-[200px] ${
              errors.endDate ? "border-red-500" : "border-black"
            }`}
            placeholder="Select end date"
          />
          {errors.endDate && (
            <p className="text-red-500 text-xs mt-1">{errors.endDate}</p>
          )}
        </div>
        <div className="relative flex flex-col items-center">
          <label htmlFor="course">Course</label>
          <select
            name="course"
            value={dates.course}
            onChange={handleDateChange}
            className={`ml-2 border border-black rounded pr-[15px] text-sm p-[7px] w-[200px] ${
              errors.course ? "border-red-500" : "border-black"
            }`}
          >
            <option value="">Select Course</option>
            {courses &&
              courses.map((course) => (
                <option value={course.code}>{course.code}</option>
              ))}
          </select>
          {errors.course && (
            <p className="text-red-500 text-xs mt-1">{errors.course}</p>
          )}
        </div>
        <div
          onClick={handleLoadData}
          className="cursor-pointer ml-2 lg:self-end p-[7px] gap-1 shadow-xl bg-[var(--primary-dark)] px-4 rounded-md text-white font-semibold flex flex-row items-center"
        >
          <BiData size={20} />
          Get data/Refresh
        </div>
      </div>
      <div className="flex flex-wrap items-center flex-col w-full">
        <div className="w-full">
          {loading ? (
            <Loading />
          ) : error ? (
            <div className="w-full text-center text-lg text-red-600 mt-[40px]">
              Oops! {error}
            </div>
          ) : data ? (
            <BalancesPaginated data={data} setData={setData} dates={dates} />
          ) : (
            <div className="w-full text-center mt-[40px] ">
              <p className="text-lg">Nothing to display here.</p>
              <p className="mt-[15px] italic">
                Please load the data by selecting the date-range above
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeesBalance;
