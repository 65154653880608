import React, { useEffect, useState } from "react";
import { readableTimestamp } from "./../../utils/readableTimeStamps";
import { Loading, QuickPassLogo } from "..";
import logo from "./../../assets/img/qpds logo.jpg";
import { jsPDF } from "jspdf";
import "jspdf-autotable"; // Import jsPDF AutoTable plugin

const Report = ({ data, allocDate, closeModal }) => {
  const [loading, setLoading] = useState(false);

  const generatePdf = () => {
    const doc = new jsPDF();

    // Add logo
    const imgWidth = 20;
    const imgHeight = 20;
    doc.addImage(logo, "JPEG", 10, 10, imgWidth, imgHeight);

    // Add title
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    doc.text("QuickPass Driving School", imgWidth + 20, 20);

    // Add subtitle
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Allocation Report", imgWidth + 20, 30);

    // Allocation and vehicle info
    doc.setFontSize(10);
    doc.text(`Allocation Date: ${allocDate || "N/A"}`, 10, 40);
    doc.text(`Vehicle: ${data[0]?.vehicle}`, 10, 45);
    doc.text(`Instructor: ${data[0]?.Vehicle?.instructor}`, 10, 50);
    doc.text(
      `Date printed: ${new Intl.DateTimeFormat("en-GB").format(new Date())}`,
      10,
      55
    );
    doc.text(`Total records: ${data.length}`, 10, 60);

    // Add table for data
    const tableColumn = ["Time", "Student", "Lessons", "Bal", "Pending"];
    const tableRows = data.map((item) => [
      item?.time,
      item?.Student?.fullname,
      item?.less,
      item?.bal,
      item?.pending,
    ]);

    doc.autoTable({
      startY: 70, // Table starts below the printed text
      head: [tableColumn],
      body: tableRows,
      theme: "striped",
      margin: { top: 20 },
      headStyles: {
        fillColor: [0, 122, 255], // Blue background for headers
        textColor: [255, 255, 255], // White text for headers
      },
      bodyStyles: {
        textColor: [0, 0, 0], // Black text for table cells
      },
    });

    // Save PDF
    doc.save(
      `${data[0].vehicle}-${allocDate} allocation_${readableTimestamp()}.pdf`
    );
  };

  useEffect(() => {
    setLoading(true);
    const print = async () => {
      await generatePdf(); // Generate PDF when component mounts
      setLoading(false);
      closeModal(); // Close the modal after generating the PDF
    };

    print();
  }, []); // Only run on mount

  return (
    <div className="container mx-auto p-6 gap-6 flex flex-col">
      {loading && <Loading />}
    </div>
  );
};

export default Report;
