import React, { useEffect, useState } from "react";
import { BiPrinter, BiSearch } from "react-icons/bi";
import { Modal, FeesHistory } from "..";
import { toKwacha } from "../../utils/toKwacha";
import { HiInformationCircle } from "react-icons/hi";
import Report from "./Report";

const Paginated = ({ data, dates }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState();
  const [modalContent, setModalContent] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);

  // Filter the data based on the search term
  const filteredData = data.filter((item) => {
    const id = item?.student?.studentID
      ? item?.student?.studentID.toString().toLowerCase()
      : "";
    const name = item?.student?.fullname
      ? item?.student?.fullname.toLowerCase()
      : "";
    const date = item?.student?.dateRegistered
      ? item?.student?.dateRegistered.toString().toLowerCase()
      : "";

    return (
      id.includes(searchTerm.toLowerCase()) ||
      name.includes(searchTerm.toLowerCase()) ||
      date.includes(searchTerm.toLowerCase())
    );
  });

  // Update the count whenever currentRecords changes
  useEffect(() => {
    setCount(filteredData.length);
    var allAmounts = [];
    allAmounts = filteredData.map((item) => item?.balance);
    const sum = allAmounts.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    setTotalAmount(sum);
  }, [filteredData]);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const totalPages = Math.ceil(filteredData.length / recordsPerPage);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleFeesHistory = (student) => {
    setModalContent(<FeesHistory student={student} closeModal={closeModal} />);
    openModal();
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleReport = () => {
    setModalContent(
      <Report
        data={filteredData}
        totalAmount={totalAmount}
        count={count}
        dates={dates}
        closeModal={closeModal}
      />
    );
    openModal();
  };

  return (
    <div className="h-full pl-[10px] w-full">
      <Modal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        modalContent={modalContent}
      />
      <div className="text-gray-400 mx-4 my-1 font-bold">
        ~{`${count} Record(s)`}~
      </div>
      {/* Search and Records per Page */}
      <div className="flex items-center md:gap-1 w-full lg:justify-between gap-4 md:justify-evenly justify-evenly md:flex-row flex-col">
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            className="ml-2 border border-black rounded pr-[34px] text-sm p-[7px]"
            placeholder="Search records ... "
          />
          <BiSearch size={20} className="absolute right-2 top-2" />
        </div>
        <div>
          Summation:{" "}
          <span className="text-lg text-[var(--primary-dark)]">
            {toKwacha(totalAmount)}
          </span>
        </div>
        <div className="md:mr-5 mr-1 flex md:ml-1 ml-8 md:pr-4">
          <label className="text-sm font-semibold">
            Records per page:
            <input
              type="number"
              value={recordsPerPage}
              onChange={handleRecordsPerPageChange}
              min={1}
              className="ml-2 p-1 border border-black rounded max-w-[100px] pl-3"
            />
          </label>
        </div>
        <BiPrinter
          size={34}
          title="Print report"
          className="mr-[20px] animate-pulse text-green-800 cursor-pointer"
          onClick={handleReport}
        />
      </div>

      {/* Table */}
      <div className="overflow-x-auto mt-4 md:pr-2 pr-1">
        <table className="min-w-full table-auto bg-white shadow-md rounded-lg text-sm">
          <thead>
            <tr className="bg-blue-500 text-white">
              <th className="px-4 py-2">Student ID</th>
              <th className="px-4 py-2">Full name</th>
              <th className="px-4 py-2">Date Registered</th>
              <th className="px-4 py-2">Balance</th>
              <th className="px-4 py-2">Total Paid</th>
              <th className="px-4 py-2">Fees Amount</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentRecords.map((item, index) => (
              <tr key={index} className="text-center hover:bg-slate-200">
                <td className="px-4 py-2">{item?.student?.studentID}</td>
                <td className="px-4 py-2">{item?.student?.fullname}</td>
                <td className="px-4 py-2">{item?.student?.dateRegistered}</td>
                <td
                  className={`px-4 py-2 text-white border-b-[1px] border-white ${
                    item?.balance > 0 ? "bg-red-600" : "bg-green-600"
                  }`}
                >
                  {toKwacha(item?.balance)}
                </td>
                <td className="px-4 py-2">{toKwacha(item?.sum)}</td>
                <td className="px-4 py-2">{toKwacha(item?.fees)}</td>
                <td className="px-4 py-2 relative text-left flex items-center gap-2">
                  <HiInformationCircle
                    size={20}
                    title="Fees History"
                    className="cursor-pointer"
                    onClick={() => handleFeesHistory(item?.student)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex flex-wrap gap-3 items-center mb-[20px] justify-center w-full mt-3 border-t-2 py-2">
        <button onClick={handleFirstPage} disabled={currentPage === 1}>
          First
        </button>
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
        <button onClick={handleLastPage} disabled={currentPage === totalPages}>
          Last
        </button>
      </div>
    </div>
  );
};

export default Paginated;
