import React, { useEffect, useRef } from "react";
import { toKwacha } from "../../utils/toKwacha";
import { jsPDF } from "jspdf";
import "jspdf-autotable"; // Import the autotable plugin
import logo from "./../../assets/img/qpds logo.jpg";

function Report({ data, totalAmount, count, dates, closeModal }) {
  const reportRef = useRef(null); // Reference to the report container

  useEffect(() => {
    if (reportRef.current) {
      const doc = new jsPDF("p", "mm", "a4");

      // Add the logo image (make sure you import the logo correctly)
      const logoImg = logo; // Use the imported logo path
      const logoWidth = 20; // Adjust the width of the logo
      const logoHeight = 20; // Adjust the height of the logo
      const logoX = 20; // X position on the PDF
      const logoY = 20; // Y position on the PDF

      // Adding the logo to the PDF at (X, Y) position
      doc.addImage(logoImg, "JPEG", logoX, logoY, logoWidth, logoHeight);

      // Add title and other text after logo
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text("Fees Balance Report", 70, 30); // Adjust title position to avoid overlap with logo

      doc.setFontSize(12);
      doc.text(
        `Date printed: ${new Date().toISOString().split("T")[0]}`,
        15,
        40
      );
      doc.text(
        `Date Range: From ${dates.startDate} to ${dates.endDate}`,
        15,
        50
      );
      doc.text(`Course: ${dates.course}`, 15, 55);
      doc.text(`Total balances: ${toKwacha(totalAmount)}`, 15, 60);

      // Use jsPDF autotable to create the table
      const tableData = data.map((item) => [
        item?.student?.studentID || "",
        item?.student?.fullname || "",
        item?.student?.dateRegistered || "",
        toKwacha(item?.balance),
        toKwacha(item?.sum),
        toKwacha(item?.fees),
      ]);

      const tableColumns = [
        "Student ID",
        "Full name",
        "Date Registered",
        "Balance",
        "Total Paid",
        "Fees Amount",
      ];

      doc.autoTable({
        startY: 65, // Table starts below the title and logo
        head: [tableColumns],
        body: tableData,
        margin: { top: 10 },
        styles: {
          fontSize: 10,
          cellPadding: 2,
          halign: "center",
        },
      });

      // Save the PDF and close the modal after download
      doc.save(
        `Fees_Balance_Report_${new Date().toISOString().split("T")[0]}.pdf`
      );
      closeModal(); // Close the modal after the PDF is generated
    }
  }, [data, dates, totalAmount, closeModal]);

  return (
    <div ref={reportRef} className="pt-[20px] flex flex-col gap-3 w-full"></div>
  );
}

export default Report;
