import React, { useEffect, useState } from "react";
import { readableTimestamp } from "./../../utils/readableTimeStamps";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import logo from "./../../assets/img/qpds logo.jpg";
import { Loading } from "./../../components";

const Report = ({ data, dates, searchTerm, closeModal }) => {
  const [loading, setLoading] = useState(false);

  const generatePdf = async () => {
    try {
      setLoading(true);

      // Create a new jsPDF instance
      const doc = new jsPDF();

      // Add logo
      doc.addImage(logo, "PNG", 10, 10, 30, 30);

      // Add report info
      doc.setFontSize(12);
      doc.text(
        `Date range: From ${dates.startDate} to ${dates.endDate || "N/A"}`,
        10,
        50
      );
      if (searchTerm) {
        doc.text(`With search term: ${searchTerm}`, 10, 60);
      }
      doc.text(
        `Date: ${new Intl.DateTimeFormat("en-GB").format(new Date())}`,
        10,
        60
      );
      doc.text(`Total records: ${data.length}`, 10, 70);

      // Add title
      doc.setFontSize(16);
      doc.text("Test Report", 105, 80, null, null, "center");

      // Add table with jsPDF autotable
      const tableData = data.map((item) => [
        item?.dateOfTest,
        item?.Student?.fullname,
        item?.testName,
        item?.passOrFail,
      ]);

      doc.autoTable({
        startY: 90,
        head: [["Date of test", "Student", "Test name", "Status"]],
        body: tableData,
        theme: "grid",
        styles: { cellPadding: 2, fontSize: 10 },
        columnStyles: {
          3: { halign: "center", cellWidth: 20 },
        },
      });

      // Save PDF
      const fileName = `test-report from ${dates.startDate} to ${
        dates.endDate || "N/A"
      } _${readableTimestamp()}.pdf`;
      doc.save(fileName);

      setLoading(false);
      closeModal(); // Close the modal after generating the PDF
    } catch (error) {
      console.error("Error generating PDF:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data && data.length > 0) {
      generatePdf();
    }
  }, [data]);

  return (
    <div className="container mx-auto gap-6 flex flex-col">
      {loading && <Loading />}
    </div>
  );
};

export default Report;
