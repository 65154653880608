import React, { useContext, useEffect, useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { toKwacha } from "../../utils/toKwacha";
import { Loading } from "..";
import { AuthContext } from "../../context/Contexts";
import logo from "./../../assets/img/qpds logo.jpg"; // Ensure the path is correct

const BatchReport = ({ data, count, closeModal, totalAmount }) => {
  const { authUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const generatePdf = () => {
    const doc = new jsPDF();
    // Add logo
    const imgWidth = 20;
    const imgHeight = 20;
    doc.addImage(logo, "JPEG", 10, 10, imgWidth, imgHeight);

    // Add title and metadata
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    doc.text("QuickPass Driving School", imgWidth + 20, 20);

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text("Receipts Report", imgWidth + 20, 30);

    // Add metadata in a single row
    doc.setFontSize(10);
    const metadata = `Printed by: ${authUser.firstname} ${
      authUser.lastname || "N/A"
    } | Date: ${new Intl.DateTimeFormat("en-GB").format(
      new Date()
    )} | Printed records: ${count}`;
    doc.text(metadata, 10, 40);

    // Add total amount
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(`Total Amount: ${toKwacha(totalAmount)}`, 10, 50);

    // Define table columns and rows
    const tableColumns = [
      "Receipt No.",
      "Date Paid",
      "Student Name",
      "Payment For",
      "Mode of Payment",
      "Amount",
      "Date Added",
    ];
    const tableRows = data.map((item) => [
      item?.receiptNo || "N/A",
      item?.dateOfPayment || "N/A",
      item?.Student?.fullname || "N/A",
      item?.paymentOf || "N/A",
      item?.modeOfPayment || "N/A",
      toKwacha(item?.amount) || "N/A",
      item?.date?.split("T")[0] || "N/A",
    ]);

    // Add table using jsPDF AutoTable
    doc.autoTable({
      head: [tableColumns],
      body: tableRows,
      startY: 60, // Position the table below the metadata
      theme: "striped",
      headStyles: { fillColor: [41, 128, 185] }, // Blue header
      styles: { fontSize: 10, halign: "center", cellPadding: 1.5 }, // Remove padding
    });

    // Save the PDF
    const fileName = `Batch_receipts_${new Date()
      .toISOString()
      .slice(0, 10)}.pdf`;
    doc.save(fileName);
  };

  useEffect(() => {
    setLoading(true);

    const generateAndClose = () => {
      generatePdf(); // Generate the PDF
      setLoading(false);
      closeModal(); // Close the modal
    };

    generateAndClose();
  }, [closeModal]);

  if (loading) {
    return <Loading />;
  }
};

export default BatchReport;
